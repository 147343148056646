import { styled, Container } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { fadeInVariant, upVariant } from '../../components/AnimationVariants';
import IconList from '../../components/IconList';
import Layout from '../../components/Layout';
import ProjectNav from '../../components/ProjectNav';
import { SEO } from '../../components/seo';
import { StyledBoxContainer, StyledBoxItem } from '../../components/StyledBox';
import { StyledTypographySubtitle } from '../../components/StyledTypography';

const Timetrack: React.FC = () =>  {
  const StyledDiv = styled('div')(({theme}) => ({
    background: 'transparent linear-gradient(270deg, #FFFFFF 0%, #E8F8FF 100%) 0% 0% no-repeat padding-box',
    opacity: 1,
    [theme.breakpoints.down("md")]: {
      paddingBottom: "8vh",
    }
  }));

  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  return (
    <Layout>
      <StyledDiv>
        <Container maxWidth={'lg'} disableGutters>
          <ProjectNav />
          <InView as="div" onChange={(inView) => { inView ? controls.start("visible") : '' }} triggerOnce={true} threshold={0.2 }>
            <StyledBoxContainer sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="timetrack_admin_mobile" src="../../images/timetrack_admin_mobile2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Attendance Tracking System
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Mobile based attendance system for our client who has thousands of employees in more than a thousand outlets all over Indonesia", "Thousands of check-ins in the morning, and check-outs in the afternoon", "Sales module for sales person on the field", "Custom integration into the business flow and existing system", "Optimization for speed for large scale and horizontal scaling"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls2.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row-reverse', alignItems: 'center', padding: "8vh 0 8vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls2} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="timetrack_mobile" src="../../images/timetrack_mobile2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls2} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Mobile Based
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Location based attendance taking", "Report new location to be added to the location database", "Convenient leave system", "Sales module for retail shop attendance", "Discreet payslip distribution for all employees"]}/>
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
          <InView as="div" onChange={(inView) => { inView ? controls3.start("visible") : '' }} triggerOnce={true} threshold={0.2}>
            <StyledBoxContainer sx={{ flexDirection: 'row', alignItems: 'center', padding: "8vh 0 16vh 0" }}>
              <StyledBoxItem>
                <motion.div animate={controls3} initial="hidden" variants={fadeInVariant}>
                  <StaticImage alt="timetrack-admin" src="../../images/timetrack_admin2.jpg" style={{ borderRadius:16 }}/>
                </motion.div>
              </StyledBoxItem>
              <StyledBoxItem>
                <motion.div animate={controls3} initial="hidden" variants={upVariant}>
                  <StyledTypographySubtitle gutterBottom component={"h3"}>
                    Admin System, Backend, Devops
                  </StyledTypographySubtitle>
                </motion.div>
                <IconList labels={["Command console to manage the attendance system", "Simple clean design based on Material UI standard", "Integration with other existing system via API and microservice architecture", "Handle the devops procedure to ensure smooth operation, monitoring and backup"]} />
              </StyledBoxItem>
            </StyledBoxContainer>
          </InView>
        </Container>
      </StyledDiv>
    </Layout>
  );
}

export default Timetrack;

export const Head = () => (
  <SEO title="Projects | Garasilabs"/>
)